import { FC } from 'react';
import IconProps from './IconProps';

export enum ArrowType {
  UP,
  RIGHT,
  DOWN,
  LEFT,
}

export interface ArrowIconProps extends IconProps {
  type: ArrowType;
}

export const ArrowIcon: FC<ArrowIconProps> = (props) => {
  const { type, onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  switch (type) {
    case ArrowType.UP:
      return (
        <svg
          onClick={props.onClick}
          className={`inline ${cursor} ${className}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          data-testid="icon-arrow-up"
        >
          <path
            fillRule="evenodd"
            d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      );
    case ArrowType.RIGHT:
      return (
        <svg
          onClick={props.onClick}
          className={`inline ${cursor} ${className}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          data-testid="icon-arrow-right"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      );
    case ArrowType.DOWN:
      return (
        <svg
          onClick={props.onClick}
          className={`inline ${cursor} ${className}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          data-testid="icon-arrow-down"
        >
          <path
            fillRule="evenodd"
            d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      );
    case ArrowType.LEFT:
    default:
      return (
        <svg
          onClick={props.onClick}
          className={`inline ${cursor} ${className}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          data-testid="icon-arrow-left"
        >
          <path
            fillRule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clipRule="evenodd"
          />
        </svg>
      );
  }
};
